import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime, formatInt, formatMoney, formatWh } from '../../lib/fmt';
import { esc, replace } from '../../lib/utils';
import { getLegalEntityDetails } from '../../services/order.service';

export default class DOrder extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="DOrder">
        {this.renderQrCode()}
        {this.renderHeader()}
        {this.renderActivities()}
        {this.renderProducts()}
        {this.renderOwnProducts()}
        {this.renderFooter()}
      </div>
    );
  }

  // render helpers

  renderQrCode() {
    return (
      <div style={{ position: 'absolute' }}>
        <img width="100" src="/img/qr_code.jpg" alt="@tigerbox_ch" />
      </div>
    );
  }

  renderHeader() {
    const { app, order } = this.props;
    const legalEntityDetails = getLegalEntityDetails(app, order);
    return (
      <div>
        <h1 className="text-center">Клубный сервис TigerBox</h1>
        <div className="text-center mb-3">
          Большая Филевская 3 стр. 1А, {legalEntityDetails.phoneNumber}, {legalEntityDetails.name},{' '}
          {legalEntityDetails.registrationNumber}
        </div>
        <h2 className="text-center">Договор НАРЯД-ЗАКАЗА на работы № {order.id}</h2>
        <hr />
        <div className="row">
          <div className="col">
            <dl className="row">
              <dt className="col-4">Приём заказа</dt>
              <dd className="col-8">{formatDateTime(order.created_at) || '–'}</dd>
              <dt className="col-4">Начало работ</dt>
              <dd className="col-8">{formatDateTime(order.started_at) || '–'}</dd>
              <dt className="col-4">Завершение</dt>
              <dd className="col-8">{formatDateTime(order.finished_at) || '–'}</dd>
              <dt className="col-4">Потребитель</dt>
              <dd className="col-8">{order.contractor_name}</dd>
              <dt className="col-4">Модель</dt>
              <dd className="col-8">
                {order.vehicle_brand} {order.vehicle_model}
              </dd>
            </dl>
          </div>
          <div className="col">
            <dl className="row">
              <dt className="col-4">Год выпуска</dt>
              <dd className="col-8">{order.vehicle_year}</dd>
              <dt className="col-4">Цвет</dt>
              <dd className="col-8">{order.vehicle_color || '–'}</dd>
              <dt className="col-4">VIN</dt>
              <dd className="col-8">{order.vehicle_vin || '–'}</dd>
              <dt className="col-4">Гос. номер</dt>
              <dd className="col-8">{order.vehicle_reg}</dd>
              <dt className="col-4">Пробег</dt>
              <dd className="col-8">{order.mileage ? `${formatInt(order.mileage, true)} км` : '–'}</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }

  renderActivities() {
    const { order } = this.props;
    const items = order.activities;
    return (
      <div className="mb-3">
        <table className="table table-bordered table-sm text-center">
          <thead>
            <tr>
              <th>Выполненные работы</th>
              <th>Н/ч</th>
              <th>Кол-во</th>
              <th>Цена н/ч</th>
              <th>Стоимость</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => this.renderActivityRow(item))}
            {items.length === 0 && this.renderActivityRow()}
          </tbody>
        </table>
        <div>Мастер приёмщик {this.getSignPlaceholder(4)}</div>
      </div>
    );
  }

  renderActivityRow(item) {
    if (!item) {
      return (
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    const { order } = this.props;
    const amount = Math.round((order.wh_price * item.wh * item.qty) / 100);
    return (
      <tr key={item.id}>
        <td>{item.activity_name}</td>
        <td>{formatWh(item.wh)}</td>
        <td>{item.qty}</td>
        <td>{formatMoney(order.wh_price, true, true)}</td>
        <td>{formatMoney(amount, true, true)}</td>
      </tr>
    );
  }

  renderProducts() {
    const { order } = this.props;
    const items = order.products;
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="mb-3">
        <h2 className="text-center">Накладная на запасные части и расходные материалы</h2>
        <table className="table table-bordered table-sm text-center">
          <thead>
            <tr>
              <th>Код</th>
              <th>Наименование</th>
              <th className="text-nowrap">Кол-во</th>
              <th>Цена</th>
              <th>Стоимость</th>
            </tr>
          </thead>
          <tbody>{items.map((item) => this.renderProductRow(item))}</tbody>
        </table>
        <div>Отпустил {this.getSignPlaceholder(4)}</div>
      </div>
    );
  }

  renderProductRow(item) {
    return (
      <tr key={item.id}>
        <td>
          {this.maskSku(item.product_sku_original)} / {item.producer_name}
        </td>
        <td>{item.product_name}</td>
        <td>{item.qty}</td>
        <td>{formatMoney(item.price, true, true)}</td>
        <td>{formatMoney(item.price * item.qty, true, true)}</td>
      </tr>
    );
  }

  renderOwnProducts() {
    const { order } = this.props;
    const items = order.own_products;
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="mb-3">
        <h2 className="text-center">Запасные части и расходные материалы потребителя</h2>
        <table className="table table-bordered table-sm text-center">
          <thead>
            <tr>
              <th>Наименование</th>
              <th className="text-nowrap">Кол-во</th>
            </tr>
          </thead>
          <tbody>{items.map((item, index) => this.renderOwnProductRow(item, index))}</tbody>
        </table>
        <div className="mb-3">
          При установке запасных частей Заказчика Исполнитель несёт ответственность только за соблюдение технологии
          выполняемых работ. Оплата повторных работ вследствии низкого качества запасных частей производится Заказчиком.
        </div>
        <div>Принял в производство {this.getSignPlaceholder(4)}</div>
      </div>
    );
  }

  renderOwnProductRow(item, index) {
    return (
      <tr key={index}>
        <td>{item.name}</td>
        <td>{item.qty}</td>
      </tr>
    );
  }

  renderFooter() {
    const { order } = this.props;
    const amount = order.activity_amount + order.product_amount;
    const nds = Math.round(amount - amount * 0.95);
    return (
      <div>
        <h2 className="text-center mt-3">Итого по наряд-заказу и накладной</h2>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td className="w-50">
                <dl className="row mb-0">
                  <dt className="col-8">Общая стоимость ремонтных работ</dt>
                  <dd className="col-4">{formatMoney(order.activity_amount, true, true)}</dd>
                  <dt className="col-8">Общая стоимость запасных частей</dt>
                  <dd className="col-4">{formatMoney(order.product_amount, true, true)}</dd>
                  <dt className="col-8">НДС</dt>
                  <dd className="col-4">{formatMoney(nds, true, true)}</dd>
                  <dt className="col-8">Общая стоимость ремонта с НДС</dt>
                  <dd className="col-4">{formatMoney(amount, true, true)}</dd>
                </dl>
              </td>
              <td>
                С объёмом работ и с первоначальной стоимостью согласен. С Правилами предоставления услуг ознакомлен.
                Согласен на согласование стоимости дополнительных и сопутстсвующих работ посредством мессенджеров,
                электронной почты или звонка по телефону.
                <br />
                <br />
                Потребитель {this.getSignPlaceholder(4)}
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <br />
                <br />
                Кассир {this.getSignPlaceholder(4)}
              </td>
              <td>
                Объём и качество выполненных работ и комплектность автотранспортного средства проверил
                <br />
                <br />
                Мастер {this.getSignPlaceholder(4)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mb-3">
          <strong>Гарантийные обязательства исполнителя:</strong> Техническое обслуживание – 20 дней или до 1000 км
          пробега, по текущему ремноту – 30 дней или до 2000 км пробега, по ремонту ДВС – один год, без ограничения
          пробега (есть дополнительные условия). Исполнитель несёт гарантийные обязательства при условии соблюдения
          потребителем правил эксплуатации и рекомендаций исполнителя по использованию результатов работы (услуги).
        </div>
        <div className="mb-3">
          <strong>Рекомендации:</strong>
          <div dangerouslySetInnerHTML={this.getHtmlWithLineBreaks(order.recommendations)} />
        </div>
        <div className="mb-3"></div>
        <div className="mb-3">
          Претензий по комплектности не имею. С объёмом выполненных работ согласен. С правилами и условиями эффективного
          и безопасного использования результатов оказанных услуг (выполненных работ) ознакомлен.
          <br />
          <br />
          Потребитель {this.getSignPlaceholder(4)}
        </div>
      </div>
    );
  }

  // other helpers

  maskSku(sku) {
    if (sku.length <= 4) {
      return sku;
    }
    return `${sku.slice(0, 2)}**${sku.slice(-2)}`;
  }

  getSignPlaceholder(size = 1) {
    return '_'.repeat(size * 10);
  }

  getHtmlWithLineBreaks(text) {
    return { __html: replace(esc(text), '\n', '<br>') };
  }
}
